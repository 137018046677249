exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-latest-offers-js": () => import("./../../../src/pages/latest-offers.js" /* webpackChunkName: "component---src-pages-latest-offers-js" */),
  "component---src-pages-offer-demo-js": () => import("./../../../src/pages/offer-demo.js" /* webpackChunkName: "component---src-pages-offer-demo-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subscribe-thank-you-js": () => import("./../../../src/pages/subscribe-thank-you.js" /* webpackChunkName: "component---src-pages-subscribe-thank-you-js" */),
  "component---src-pages-thank-you-career-js": () => import("./../../../src/pages/thank-you-career.js" /* webpackChunkName: "component---src-pages-thank-you-career-js" */),
  "component---src-pages-thank-you-enquiry-js": () => import("./../../../src/pages/thank-you-enquiry.js" /* webpackChunkName: "component---src-pages-thank-you-enquiry-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/BlogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/Brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/ProductDetailPage.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-product-last-child-js": () => import("./../../../src/templates/ProductLastChild.js" /* webpackChunkName: "component---src-templates-product-last-child-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/Profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-storage-component-js": () => import("./../../../src/templates/StorageComponent.js" /* webpackChunkName: "component---src-templates-storage-component-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/Team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/Testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-navbar-js": () => import("./../../../src/components/Navbar.js" /* webpackChunkName: "slice---src-components-navbar-js" */)
}

